<template>
  <div class="logo-box" style="margin-right:auto">
    <div class="logo">
      <a href="/">
        <img :class=color src="/images/logo-ukp-brand.png"/>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "LogoBox",
  props: ["color"],
};
</script>
