import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import router from "./router";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { getDeviceId } from "./helpers/device_id";

const toaster = createToaster({
  position: "top-right",
  dismissible: true,
  duration: 3000,
});

const deviceId = getDeviceId();

export const $axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Device-ID": deviceId  // Add the generated device ID here

  },
  timeout: 30000,
});

$axios.interceptors.response.use(
  function (response) {
    if(SUCCESS_RESPONSE.includes(response.config.url)){
      if(response?.data?.message){
        toaster.success(response?.data.message);
      }
    }

    if(SUCCESS_FILE_UPLOAD.includes(response.config.url)){
      if(response?.data?.storage?.link) toaster.success("Upload Success");
    }

    return response;
  },
  function (error) {
    if (!error.response) {
      toaster.error("Request Timeout");
      return;
    }

    if (error.response.status == 422) {
      var errors = error.response.data.errors
        ? error.response.data.errors
        : error.response.data;

      if (typeof errors === 'string') {
        toaster.error(errors);
      } else {
        for (var key in errors) {
          var validation_error = errors[key];
          toaster.error(validation_error);
        }
      }
    } else if (error.response.status == 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      toaster.error(
        error.response.data.message +
          "<br> Anda Tidak Bisa Mengakses/Melakukan Aksi di  Halaman Ini"
      );

      delete $axios.defaults.headers.Authorization;
      router.push("/login");
      setTimeout(function () {
        // window.location.reload();
      }, 1500);
    } else if (error.response.status == 403) {
      toaster.error(
        error.response.data.message +
          "<br> Anda Tidak Bisa Mengakses/Melakukan Aksi di  Halaman Ini"
      );

      setTimeout(function () {
        // window.location.reload();
      }, 1500);
    }

    return error
  }
);
export default $axios;

export const END_POINTS  = {
  user:{
    current_stage : `user/stage`,
    me: 'me'
  },
  resource : {
      pendidikan : `resource/pendidikan`,
      negara: `resource/negara`,
      pekerjaan: `resource/pekerjaan`,
      agama: 'resource/agama',
      sekolah: 'resource/sekolah',
      sekolah_id: (id)=> `resource/sekolah/${id}`,
      kota_sekolah: 'resource/kota_sekolah',
      jenjang: 'resource/jenjang',
      disabilitas: 'resource/disabilitas',
      kurikulum: 'resource/kurikulum',
      prodi: 'resource/prodi',
      track: 'resource/track',
      prodi_track: (id_prodi)=> `resource/prodi/${id_prodi}/track`,
      kota: 'resource/kota',
      provinsi: 'resource/provinsi',
      kecamatan: 'resource/kecamatan',
      negara: 'resource/negara',
      negara_orang_tua: 'resource/negara/orangtua',
      warga_negara: 'resource/warga_negara',
      faq: 'resource/faqs',
      jurusan_sma: 'resource/jurusan_sma',
      gender: 'resource/gender'
  },
  form:  {
      tahap1 : `form_1`,
      tahap2 : `form_2`,
      tahap5 : `form_3`,
      tahap4 : `seleksi`,
      save_form_3 : 'form_3/save'
  },
  file: {
    kartu_keluarga : 'form_3/kartu_keluarga',
    akta_kelahiran : 'form_3/akta_kelahiran',
    surat_pernyataan : 'form_3/surat_pernyataan',
    surat_pernyataan_track : 'form_3/surat_pernyataan_track',
  },
  payment: {
    billing_detail : 'payment',
    kode_promo : (kode_promo) =>`kode_promo/${kode_promo}`,
    pac : 'pac/payment',
    pac_price : 'pac',
    simulation_price: '/price/simulate'
  },
  seleksi: {
    accept: ({no_pac, id_seleksi})=> `seleksi/accept/${no_pac}/${id_seleksi}`,
    reject: ({no_pac, id_seleksi})=> `seleksi/reject/${no_pac}/${id_seleksi}`,
    change_course: ({nomor_ujian})=> `seleksi/change_course/${nomor_ujian}`,
    apply_new: `seleksi/apply_new`,
    accepted: `seleksi/accepted`,
    check_test : `petra/check_test`,
    jadwal_quiz: 'quiz/jadwal',
    submit_quiz: ({no_pac}) => `seleksi/submit_schedule/${no_pac}`
  },
  definitif: {
    get: 'definitif',
    payment: 'price/create-va',
    get_va: 'price/get-va',
    delete_va: 'price/delete-va',
    final: 'definitif/final',
    cetak: 'definitif/cetak',
    kode_voucher: 'kode_voucher'
  },
  quiz: {
    jadwal_quiz: 'quiz/jadwal',
    getMyQuiz: 'quiz',
    getQuizDetail: (noreg, module_id) => `quiz/${noreg}/${module_id}`,
    getQuizQuestion: (noreg, module_id) => `quiz/${noreg}/${module_id}/get`,
    startQuiz: (noreg, module_id) => `quiz/${noreg}/${module_id}/start`,
    submitAnswerQuiz: (noreg, id_question) => `quiz/${noreg}/${id_question}/submit`,
    getMyAnswer: (noreg, id_question, id_camas) => `quiz/${noreg}/${id_question}/${id_camas}/answer`,
    updateDevice: `quiz/update_device`,
    finishQuiz: (id_quiz_camas, no_reg) => `quiz/finish_quiz/${id_quiz_camas}/${no_reg}`
  },
  encrypt: (no_reg)=>`petra/encrypt?kode_registrasi=${no_reg}`,
}

const SUCCESS_RESPONSE = [END_POINTS.form.tahap5]

const SUCCESS_FILE_UPLOAD = [END_POINTS.file.akta_kelahiran, END_POINTS.file.kartu_keluarga, END_POINTS.file.surat_pernyataan]


export const useAuthedQuery = (...options) => {
  const query = useQuery(...options);
  if (query?.error?.response?.status === 401) {
      (async ()=> {
        toaster.error("Error");
          // TODO CLEAR CACHE and clear token in here


      })();

      return {};
  }
  return query;
};

export const useAuthedMutation = (...options) => {
  const mutation = useMutation(...options);
  if (mutation?.error?.response?.status === 401) {
      (async ()=> {
        toaster.error("Error");
          // TODO CLEAR CACHE and clear token in here


      })();

      return {};
  }
  if (mutation?.error?.response?.status === 422) {
    (async ()=> {
      toaster.error("Error");
        // TODO CLEAR CACHE and clear token in here


    })();

    return {};
}
  return mutation;
};