export const PETRA_TEST_SERVICES = process.env.VUE_APP_PETRA_TEST_SERVICES
export const PETRA_TOKEN_SERVICES = process.env.VUE_APP_PETRA_TOKEN_SERVICES
export const PETRA_APP_ID = process.env.VUE_APP_PETRA_APP_ID
export const PETRA_APP_SECRET = process.env.VUE_APP_PETRA_APP_SECRET
export const PETRA_QUIZ_URL = process.env.VUE_APP_PETRA_QUIZ_URL

export const ROUTE_KEYS = {
    AUTHED_ROUTE : {
        stage_1 : '/stage-1',
        stage_2 : '/stage-2',
        stage_3 : '/stage-3',
        stage_4_quiz: '/stage-3-quiz',
        quiz: '/quiz',
        quiz_student: '/quiz-student',
        stage_4 : '/stage-4',
        stage_5 : '/stage-5',
        stage_6 : '/stage-6',
        stage_7 : '/stage-7',
    }
}

export const STAGES = [
    {
        id: 1,
        stage: 'main.home.stage1',
        title : 'main.home.registration',
        icon : 'fa fa-light fa-graduation-cap',
        to: ROUTE_KEYS.AUTHED_ROUTE.stage_1,
        key: 'form_1'
    },
    {
        id: 2,
        stage: 'main.home.stage2',
        title : 'main.home.adminselect1',
        icon : 'fa fa-light fa-book-open-reader',
        to: ROUTE_KEYS.AUTHED_ROUTE.stage_2,
        key: 'form_2'
    },
    {
        id: 3,
        stage: 'main.home.stage3',
        title : 'main.home.selectionresult',
        icon : 'fa fa-light fa-book-open-reader',
        to: ROUTE_KEYS.AUTHED_ROUTE.stage_3,
        key: 'seleksi'
    },
    {
        id: 4,
        stage: 'main.home.stage4',
        title : 'main.home.adminselect2',
        icon : 'fa fa-light fa-book-open-reader',
        to: ROUTE_KEYS.AUTHED_ROUTE.stage_4,
        key: 'form_3'
    },
    {
        id: 5,
        stage: 'main.home.stage5',
        title : 'main.home.adminkeuangan',
        icon : 'fa fa-light fa-credit-card',
        to: ROUTE_KEYS.AUTHED_ROUTE.stage_5,
        key: 'administrasi_keuangan'
    },
    {
        id: 6,
        stage: 'main.home.stage6',
        title : 'main.home.definitive',
        icon : 'fa fa-light fa-user-check',
        to: ROUTE_KEYS.AUTHED_ROUTE.stage_6,
        key: 'definitif'
    },
]

export const ADMISSION_TESTS = [
    {
        id:1,
        title: "Academic Test",
        card_color: '#E8EEFF',
        quiz_time: 60
    },
    {
        id:2,
        title: "Drawing Test",
        card_color: '#EEE5CE',
        quiz_time: 15
    },
    {
        id:3,
        title: "Interview Session",
        card_color: '#D4EFEB',
        quiz_time: 0
    },
    {
        id:4,
        title: "English Proficient Test",
        card_color: '#F7E1D5',
        quiz_time: 0
    },
    {
        id:5,
        title: "Logic Test",
        card_color: '#EFE1FB',
        quiz_time: 0
    },
]

export const ADMISSION_TEST_RECORD = {
    1 : {
        id: 1,
        title: "Drawing Test",
        card_color: '#EEE5CE',
        quiz_time: 0
    },
    2 : {
        id: 2,
        title: "Interview Session",
        card_color: '#D4EFEB',
        quiz_time: 0
    },
    3 : {
        id: 3,
        title: "Toefl Test",
        card_color: '#EFE1FB',
        quiz_time: 0
    },
    4 : {
        id: 4,
        title: "Academic English",
        card_color: '#E8EEFF',
        quiz_time: 60
    },
    5 : {
        id: 5,
        title: "Academic Math",
        card_color: '#E8EEFF',
        quiz_time: 60
    },
    6 : {
        id: 5,
        title: "Logic Test",
        card_color: '#EEE5CE',
        quiz_time: 60
    },
}

export const GOLONGAN_DARAH = [
    {   
        id : "A",
        nama : "A"
    },
    {   
        id : "B",
        nama : "B"
    },
    {   
        id : "AB",
        nama : "AB"
    },
    {   
        id : "O",
        nama : "O"
    },
]

export const CLASS_CODE_TO_NAME = {
    QF : 'QUALIFIED',
    HD : 'HIGH DISTINCTION',
    DT : 'DISTINCTION'
}

export const UKURAN_OPTION = [
    {id: 'S', nama : 'S'},
    {id: 'M', nama : 'M'},
    {id: 'L', nama : 'L'},
    {id: 'XL', nama : 'XL'},
    {id: 'XXL', nama : 'XXL'},
    {id: 'XXXL', nama : 'XXXL'},
    {id: '4XL', nama : '4XL'},
    {id: '5XL', nama : '5XL'},
    {id: '8XL', nama : '8XL'},
]