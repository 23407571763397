const generateDeviceId = () => {
    const navigatorInfo = window.navigator;
    const screenInfo = window.screen;
    
    // Collect basic information from the navigator and screen objects
    let uid = navigatorInfo.userAgent.replace(/\D+/g, '');
    uid += navigatorInfo.language;
    uid += screenInfo.height || '';
    uid += screenInfo.width || '';
    uid += screenInfo.colorDepth || '';
    uid += Date.now();

    // Generate a hash from the collected information
    let hash = 0;
    for (let i = 0; i < uid.length; i++) {
        const char = uid.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32bit integer
    }

    return 'uid_' + Math.abs(hash).toString(36);
};

export const getDeviceId = () => {
    let deviceId = localStorage.getItem('device_id');
    if (!deviceId) {
        deviceId = generateDeviceId();
        localStorage.setItem('device_id', deviceId);
    }
    return deviceId
}