import { createApp } from "vue";
import "remixicon/fonts/remixicon.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import router from "./router";
import $axios from "./api";
import store from "./store";
import App from "./App.vue";
import i18n from "./i18n";
import vue3GoogleLogin from 'vue3-google-login'
import { QueryClient, VueQueryPlugin } from "@tanstack/vue-query";
import { createHead } from '@vueuse/head'
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

const head = createHead()

export const myClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            cacheTime: 1000 * 60 * 60 // 1 hour
        }
    }
});

const app = createApp(App)
    .use(i18n)
    .use(router)
    .use(VueSweetalert2)
    .use(store)
    .use(VueQueryPlugin, {queryClient: myClient})
    .use(VueToast, {
        position: "top-right",
        duration: 1000,
    })
    .use(head)
    .use(LoadingPlugin)
    .use(vue3GoogleLogin, {
        clientId: '116000969960-nt6jh2oiirei2655j2nr065936c5cr00.apps.googleusercontent.com'
    });

app.config.globalProperties.$axios = $axios;
app.config.globalProperties.$formatNumber = function(value) {
    return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '';
};
app.provide('$axios', $axios);

app.mount("#app");
