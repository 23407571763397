import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      locale: localStorage.getItem("locale"),
      fallbackLocale: localStorage.getItem("fallbackLocale"),
      filter: {
        alumni: JSON.parse(localStorage.getItem("alumniFilter")),
        vacancy: JSON.parse(localStorage.getItem("vacancyFilter")),
      },
    };
  },
  mutations: {
    updateUser(state, newUser) {
      localStorage.setItem("user", JSON.stringify(newUser));
      state.user = newUser;
    },
    updateLocale(state, locale) {
      localStorage.setItem("locale", locale);
      localStorage.setItem("fallbackLocale", locale);
      state.locale = locale;
      state.fallbackLocale = locale;
    },
    updateVacancyFilter(state, newFilter) {
      localStorage.setItem("vacancyFilter", JSON.stringify(newFilter));
      state.filter.vacancy = newFilter;
    },
    updateAlumniFilter(state, newFilter) {
      localStorage.setItem("alumniFilter", JSON.stringify(newFilter));
      state.filter.alumni = newFilter;
    },
  },
});

export default store;
