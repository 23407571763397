<template>
  <component :is="layoutComponent" />
</template>

<script setup>
import { ref, computed, watch, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import MainLayout from "./components/Layouts/MainLayout.vue";
import AuthLayout from "./components/Layouts/AuthLayout.vue";
import BlankLayout from "./components/Layouts/Blank.vue";
import Notfound from "./components/Layouts/NotFound.vue";
import { useHead } from '@vueuse/head'

const siteData = reactive({
      title: `Universitas Kristen Petra`,
      description: `Universitas Kristen Petra adalah tempat di mana global socioleaders dibentuk dan dikembangkan seluruh potensinya. Temukan versi terbaik dirimu bersama kami.`,
    })

useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        ],
     
    })

const store = useStore();
const route = useRoute();

const isLoggedIn = ref(false);
const user = ref({});

onMounted(() => {
  user.value = store.state.user;
  checkAuth();
});

watch(() => store.state.user, (newUser) => {
  user.value = newUser;
});

const checkAuth = () => {
  if (localStorage.getItem("user") && localStorage.getItem("token")) {
    isLoggedIn.value = true;
  } else {
    isLoggedIn.value = false;
  }
};

const layoutComponent = computed(() => {
  switch (route.meta.layout) {
    case 'main':
      return MainLayout;
    case 'auth':
      return AuthLayout;
    case 'blank':
      return BlankLayout;
    default:
      return Notfound;
  }
});

</script>

<style lang="scss">
@import '@/assets/scss/custom.scss';
</style>
