import { myClient } from "@/main";

export default async (to, from, next) => {
  // Check if the path is '/login/by_admin' and there's a token in the query params
  if (to.path === '/login/by_admin' && to.query.token) {
    // Store the token in localStorage
    localStorage.setItem('token', to.query.token);

    // Proceed without redirecting
    next();
    return;
  }

  // Existing logic: redirect if there's a token in localStorage
  if (localStorage.getItem('token')) {
    next('/');
    return;
  }

  // Proceed normally if none of the above conditions are met
  next();
};